/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Container, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { StatCard } from '../../components/Card';
import { ElasticFacetFilter } from '../../components/ElasticFilter';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { TableDateFilter, TableKeywordSearch, TablePagination, TableShipperFilter,TableClearFilter,TimeZoneFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, toLocalDate, toLocalTime } from '../../helpers';
import { storeShipper } from '../../stores/slice';

import ReactGA from "react-ga4"

const RatingDashboard = () => {

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/feedback",
		})
	}, [])

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myShippers = useSelector((state) => state.slice.SHIPPERS);

	const [spinner, showSpinner] = useState(false);
	const [ratings, setRatings] = useState([])
	const [resetDate,setRestDate] = useState(0);
	const [carrier, setCarrier] = useState()
	const [facets, setFacets] = useState({})
	const [keyword, setKeyword] = useState('')
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [ratingCount, setRatingCount] = useState(0)
	const [shipmentCount, setShipmentCount] = useState(0)
	const [ratingAverage, setRatingAverage] = useState(0)
	const [npsAverage, setNpsAverage] = useState(0)
	const [timeZonee, setTimeZone] = useState("America/New_York");
	const [tz, setTz] = useState("EST");
	
	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz(timeZonee || 'America/New_York')?.subtract( 'days').startOf('day').unix(),
		toDate: moment().tz(timeZonee || 'America/New_York')?.endOf('day').unix()
	});
	useEffect(() => {
		setTimeZone(myShipper?.shipper?.timezone?.id)
		setTz(myShipper?.shipper?.timezone?.alias)
		if(keyword) setPageCount(0)
		const delay = setTimeout(() => { getElasticRatings(); }, DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword, dateFilters, myShipper,pageNumber,carrier,myShippers])

	

	const getElasticRatings = async () => {
		showSpinner(true)
		try {
			const apiName = 'api';
			const path = `/search/shipment-rating?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					aggs: {
						carrier: {
							terms: {
								"field": "shipment.carrier.name.keyword",
								"size": 1000
							}
						}
					},
					sort: [
						{
							"_score": { "order": "desc" }
						},
						{
							"createdAt": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					query: {
						bool: {
							must: []
						}
					}
				}
			}

			if (!keyword) {
				init.body.query.bool.must.push({
					range: {
						"createdTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				delete init.body.sort

				let fields = [
					"shipment.shipTo.name^3",
					"shipment.shipTo.first_name^2",
					"shipment.shipTo.last_name",
					"shipment.shipTo.phone",
					"shipment.customer.extId",
					"shipment.extId"
				]
				if (+keyword) {
					fields.push("shipment.number")
				}
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}


			if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper.id } })
			else if (myShippers) {
				let shipperIdArr = []
				myShippers.forEach((item) => {
					shipperIdArr.push(item.shipper.id)
				})
				init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
			}
			if (carrier) init.body.query.bool.must.push({ match: { "shipment.carrier.name.keyword": carrier } })
			let data = await API.post(apiName, path, init);
			
			setRatings(data.hits.hits)
			setFacets(data.aggregations)
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			setRatingCount(data?.hits?.total?.value);
			showSpinner(false)


			if (data.hits.hits.length > 0) {
				let ratingAverage = 0;
				let npsAverage = 0;

				let ratingHitsLength = 0
				let npsHitsLength = 0
				let promoters = 0
				let detractors = 0

				for (var item of data.hits.hits) {
					if (item._source.rating) {
						ratingHitsLength++
						ratingAverage += item._source.rating
					}
					if (item._source.nps) {
						npsHitsLength++
						if (item._source.nps > 8) {
							promoters++
						} else if (item._source.nps < 7) {
							detractors++
						}
					}
				}

				ratingAverage = (ratingAverage / ratingHitsLength).toFixed(2);
				ratingAverage = !isNaN(ratingAverage) ? ratingAverage : 0;
				setRatingAverage(ratingAverage);

				npsAverage = ((promoters - detractors) / npsHitsLength) * 100
				npsAverage = !isNaN(npsAverage) ? npsAverage : 0;
				setNpsAverage(Math.round(npsAverage));
			} else {
				setRatingAverage(0);
				setNpsAverage(0);
			}


			//*** get delivered data ***//
			const shipmentInit = {
				body: {
					query: {
						bool: {
							must: [
								{
									match: { status: "DELIVERED" }
								}
							]
						}
					}
				}

			}

			if (!keyword) {
				shipmentInit.body.query.bool.must.push({
					range: {
						"createdTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {

				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("number")
				}
				shipmentInit.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}


			if (myShipper?.shipper?.id) shipmentInit.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper.id } })
			else if (myShippers) {
				let shipperIdArr = []
				myShippers.forEach((item) => {
					shipperIdArr.push(item.shipper.id)
				})
				shipmentInit.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
			}
			if (carrier) shipmentInit.body.query.bool.must.push({ match: { "carrier.name": carrier } })

			let deliveredData = await API.post(apiName, `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`, shipmentInit);
			setShipmentCount(deliveredData?.hits?.total?.value);

		} catch (error) {
			showSpinner(false)
			console.error("error from elastic search Feedback", error)
		}

	}
	const clearFilters =()=>{
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0 )
		setDateFilters({
			fromDate: moment().tz('America/New_York')?.subtract('days').startOf('day').unix(),
			toDate: moment().tz('America/New_York')?.endOf('day').unix()
		});
		getElasticRatings()
		setCarrier(null)
		setTimeZone("America/New_York");
		setTz("EST");
        dispatch(storeShipper(myShippers))
	}
	const tzHandle = (e) => {
		setTimeZone(e);
	  };
	  
	return (
		<>
			<PageHeader name='Ratings'>
				<Row>
					<TableShipperFilter />
					<ElasticFacetFilter name={'Courier'} item={carrier} facetName={'carrier'} facets={facets} onChange={setCarrier} />
					<TableDateFilter key={resetDate} timezone={myShipper?.shipper?.timezone?.id} onChange={setDateFilters} startOf={0} />
					<TimeZoneFilter
								title={""}
								setTimeZone={setTimeZone}
								dark={true}
								onChange={tzHandle}
								tz={tz}
								setTz={setTz}
							/>
					<TableClearFilter onClick={clearFilters} />

				</Row>
			</PageHeader>

			<Container fluid>
				<Row>
					<StatCard title='Response Rate' value={(ratingCount > 0 && shipmentCount > 0) ? ((ratingCount * 100) / shipmentCount).toFixed(2) : '-'} icon='percent' />
					<StatCard title='Average Rating' value={ratingAverage ?? '-'} icon='star' />
					<StatCard title='Average NPS' value={npsAverage ?? '-'} icon='thumbs-up' />
				</Row>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm' className='mb-0' >
							<thead>
								<tr>
									<th className='text-center'>Shipment #</th>
									{!myShipper && <th> Shipper</th>}
									<th style={{ wordWrap: "break-word", maxWidth: "200px" }} >Patient / Feedback</th>
									<th className='text-center'>Rating</th>
									<th className='text-center'>NPS</th>
									<th className='text-center'>Date</th>
								</tr>
							</thead>
							<tbody>
								{
									ratings && ratings.map((rating) => {
										return (
											<tr key={rating?._source?.id} onClick={() => navigate(`/shipment/${rating?._source?.shipmentId}`)} style={{ cursor: 'pointer' }}>
												<td className='text-center'>{rating?._source?.shipment?.number}</td>
												{!myShipper && <td >{rating?._source?.shipper?.name}</td>}
												<td style={{ wordWrap: "break-word", maxWidth: "200px" }} >
													{rating?._source?.feedback || '-'}
													<div className='text-small text-muted text-wrap'>{rating?._source?.shipment.shipTo?.name}</div>
												</td>
												<td className='text-center tables-handle' style={{ width: '118px' }} >
													<StarRatings rating={rating?._source?.rating || 0} starRatedColor='#FFCD3C' starDimension='14px' starSpacing='1px' />
												</td>
												<td className='text-center tables-handle d-flex align-items-center justify-content-center'>
													{rating?._source?.nps ? <div className={`nps nps-${rating?._source?.nps * 10}`}></div> : '-'}
												</td>
												<td className='text-center'>
													{toLocalTime(rating?._source?.createdTime, rating?._source?.shipper.timezone?.id)}
													<div className='small text-muted'>{toLocalDate(rating?._source?.createdTime, rating?._source?.shipper.timezone?.id)}</div>
												</td>
											</tr>
										)
									})
								}
							</tbody>
							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={7} />
						</Table>
					</Spinner>
				</Card>
			</Container>
		</>
	);
};

export default RatingDashboard;

