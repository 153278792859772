import moment from "moment";
import React from "react";
import { formatNumber } from "../../helpers";
let QRCode = require("qrcode.react");

const Label = (props) => {
	return (
		<div className="label-content border border-dark mt-2 rounded">
			<div className="border-bottom border-dark hearder-date-font">
				<p className="d-flex text-uppercase justify-content-center font-weight-bold my-1">
					{props?.shipment?.deliveryService?.name}
				</p>

			</div>
			<div className="row">
				<div className="col-3 d-flex border-end border-dark justify-content-center align-self-center m-0 pb-0">
					<h1 className="p-0 m-0" style={{ fontSize: "85px", fontFamily: "Arial", fontWeight: "700" }}>
						{props?.shipment?.isGreenPhox ? "G" : props?.shipment?.deliveryService?.tatMax <= 4 ? "A" : props?.shipment?.deliveryService?.type == "TODAY" ? "B" : "C"}
					</h1>
				</div>
				<div className="col-6 d-flex justify-content-start align-self-center mt-3 ">
					<div>
						<h1 className="m-0 p-0" style={{ fontSize: '27px', fontWeight: "800" }}>
							{props?.shipment?.number}
						</h1>
						<h6 className="mb-1 ms-1 p-0">
							{props?.shipment?.extId}
						</h6>
						{
							props?.shipment?.isGreenPhox &&
							<h3>
								< img src="/img/greenphox-black.svg" alt="green-phox-icon rounded-circle" className="p-0 mb-1 me-1" height={'15px'} width={'15px'} />
								{props?.shipment?.box?.name || props?.shipment?.box?.number}
							</h3>

						}
					</div>
				</div>
				<div className="col-3  d-flex justify-content-center align-self-center">
					<img src="/img/new-logo.png" alt="phox-logo" height="42px" />
				</div>
			</div>
			<div className="row">
				<div className="col-12 ">
					<h1 className="d-flex justify-content-center align-item-center border-top border-bottom m-0 border-dark py-1">
						{props?.shipment?.expectedDeliveryTime && moment.unix(props?.shipment?.expectedDeliveryTime).tz(props?.shipment?.shipFrom?.timezone?.id).format("MMM DD, YYYY")}
					</h1>
				</div>
			</div>
			<div className="row p-2">
				<div className="col-6 ">
					{props?.shipment?.shipFrom && (
						<div>
							<p className="p-0 mb-0 ship-header-font" > SHIP FROM:</p>
							<h4 className="mb-0 label-small-font" >  {props?.shipment?.shipFrom?.alias || props?.shipment?.shipFrom?.name}</h4>
							<div className="label-small-font " >{props?.shipment?.shipFrom?.address?.address1}</div>
							<div className="label-small-font " >
								{props?.shipment?.shipFrom?.address?.city}
								{props?.shipment?.shipFrom?.address?.state && ", "}
								{props?.shipment?.shipFrom?.address?.state}
								{props?.shipment?.shipFrom?.address?.postalCode && ", "}
								{props?.shipment?.shipFrom?.address?.postalCode}
							</div>
							<div className="label-small-font " >{formatNumber(props?.shipment?.shipFrom?.phone)}</div>
						</div>
					)}

					{props?.shipment?.shipTo && (
						<div className="text-left mt-3">
							<p className="mb-0 p-0 ship-header-font" >SHIP TO:</p>
							<h4 className="m-0" style={{ fontWeight: "800", fontSize: "16px" }}>
								{props?.shipment?.shipTo?.name &&
									`${props?.shipment?.shipTo?.name}`}
							</h4>
							{props?.shipment?.shipTo?.company && (
								<div className="label-small-font" >{props?.shipment?.shipTo?.company}</div>
							)}
							<div className="label-small-font " >
								{props?.shipment?.shipTo?.address?.address1}
								{props?.shipment?.shipTo?.address?.address2 && ", " + props?.shipment?.shipTo?.address?.address2}
							</div>
							<div className="label-small-font " >
								{props?.shipment?.shipTo?.address?.city}
								{props?.shipment?.shipTo?.address?.state && ", "}
								{props?.shipment?.shipTo?.address?.state}
								{props?.shipment?.shipTo?.address?.postalCode && ", "}
								{props?.shipment?.shipTo?.address?.postalCode}
							</div>
							{props?.shipment?.shipTo?.phone?.length > 10 && (
								<div className="label-small-font " >{formatNumber(props?.shipment?.shipTo?.phone)}</div>
							)}
						</div>
					)}
				</div>
				<div className="col mt-5 me-2">
					{props?.shipment?.packageCount &&
						<h4 className="text-center"> Package {props?.idx} of {props?.shipment?.packageCount}</h4>
					}
					<div className="text-end align-self-end">
						{props?.qrCode && <QRCode.QRCodeSVG value={props?.qrCode} size={155} />}
					</div>
				</div>
			</div>
			{
				(props?.shipment?.deliveryInstructions || props?.shipment?.services?.includes("ADULT_SIGNATURE")) &&
				<hr className="border-dark m-0" />
			}
			<div className="row" style={{ height: "100px" }}>
				<div className="m-1">
					{(props?.shipment?.deliveryInstructions || props?.shipment?.services?.includes("ADULT_SIGNATURE")) &&
						<h5 className="my-0 ">Delivery Instructions</h5>
					}
					{
						props?.shipment?.deliveryInstructions &&
						<div className="col-12 fs-5" >
							{props?.shipment?.deliveryInstructions}
						</div>
					}
				</div>
			</div>
			<div className="ms-1">{props?.shipment?.services?.includes("ADULT_SIGNATURE") && <h5 className="d-inline-block "> <i className="fe fe-edit"></i> ADULT SIGNATURE REQUIRED</h5>}</div>
			<div className="ms-1">{(props?.shipment?.services?.includes("REFRIGERATED") || props?.shipment?.services?.includes("DRY_ICE") || props?.shipment?.services?.includes("COLD_CHAIN")) && <h5 className="d-inline-block">  <i className="fe fe-thermometer"></i>  COLD CHAIN</h5>}</div>
		</div>
	)
}

const PrintLabel = (props) => {
	return (
		<div className="shipment-label" >
			{
				props?.shipment?.packageCount ?
					Array.from({ length: props?.shipment?.packageCount }, (_, index) => (
						<Label key={index} idx={index + 1} {...props} />
					)) : <Label {...props} />
			}
		</div >
	);
};

export default PrintLabel;
