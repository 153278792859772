/* eslint-disable react-hooks/exhaustive-deps */
import { Amplify } from 'aws-amplify';
import { Toaster } from 'react-hot-toast';
import { Route, Routes } from 'react-router-dom';
import awsExport from './aws-exports';
import AdminLayout from './components/AdminLayout';
import AuthLayout from './components/Auth';
import Host from './data/host.json';
import PageNotFound from './pages/PageNotFound';
import Unauthorized from './pages/Unauthorized';
import Login from './pages/auth/Login';
import Logout from './pages/auth/Logout';
import Dashboard from './pages/dashboard/Dashboard';
import LiveTracker from './pages/dashboard/LiveTracker';
import Rating from './pages/dashboard/Rating';
import BoxDetails from './pages/greenphox/BoxDetails';
import BoxManagement from './pages/greenphox/BoxManagement';
import BoxShipments from './pages/greenphox/BoxShipments';
import PatientEditor from './pages/patient/PatientEditor';
import PatientList from './pages/patient/PatientList';
import Profile from './pages/profile/Profile';
import PublicFeedback from './pages/public/Feedback';
import PublicTracker from './pages/public/Tracker';
import Dispatch from './pages/shipment/Dispatch';
import ShipmentCreate from './pages/shipment/ShipmentCreate';
import ShipmentDetail from './pages/shipment/ShipmentDetail';
import ShipmentList from './pages/shipment/ShipmentList';
import UserInvite from './pages/user/UserInvite';
import UserList from './pages/user/UserList';
import UserOverview from './pages/user/UserOverview';
import ReactGA from 'react-ga4';

import '@aws-amplify/ui-react-geo/styles.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import 'flatpickr/dist/flatpickr.min.css';
import 'flatpickr/dist/themes/light.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-image-gallery/styles/css/image-gallery.css';
import 'react-phone-input-2/lib/style.css';
import PerformanceDashboard from './pages/dashboard/PerformanceDashboard';
import { init as initFullStory } from '@fullstory/browser';
import UspsScanForm from './pages/shipment/UspsScanForm';


let awsExportConfig = awsExport;
if (Object.keys(Host).includes(window.location.host)) {
	awsExportConfig.oauth = Host[window.location.host].oauth;
}

Amplify.configure(awsExportConfig);
initFullStory({ orgId: process.env.REACT_APP_FULL_STORY_ORG_ID })
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY);

export const getHostById = /* GraphQL */ `
	query GetHost($id: ID!) {
		getHost(id: $id) {
			id
			shipperGroup {
				name
				image
				settings
			}
		}
	}`;

function App() {

	return (
		<>
			<Routes>
				<Route exact path='/t/:id' element={<PublicTracker />} />
				<Route exact path='/f/:id' element={<PublicFeedback />} />

				<Route element={<AuthLayout />} >
					<Route exact path='/' element={<Login />} />
					<Route exact path='/auth/logout' element={<Logout />} />
				</Route>

				<Route element={<AdminLayout />} >
					{/* dashboards   */}
					<Route exact path='/dashboard' element={<Dashboard />} />
					<Route exact path='/dashboard/staff-performance' element={<PerformanceDashboard />} />
					<Route exact path='/feedback' element={<Rating />} />
					<Route exact path='/map' element={<LiveTracker />} />

					<Route exact path='/shipment' element={<ShipmentList />} />
					<Route exact path='/shipment/:id' element={<ShipmentDetail />} />
					<Route exact path='/shipment/create' element={<ShipmentCreate />} />
					<Route exact path='/dispatch' element={<Dispatch />} />
					<Route exact path='/shipment-manifest' element={<UspsScanForm />} />

					<Route exact path='/profile' element={<Profile />} />

					<Route exact path='/user' element={<UserList />} />
					<Route exact path='/user/invite' element={<UserInvite />} />
					<Route exact path='/user/:userId/overview' element={<UserOverview />} />

					{/* Patient */}
					<Route exact path='/patient' element={<PatientList />} />
					<Route exact path='/patient/create' element={<PatientEditor />} />
					<Route exact path='/patient/edit/:id' element={<PatientEditor />} />

					{/* Green Phox  */}
					<Route exact path='/box/:id' element={<BoxShipments />} />
					<Route exact path='/box/details/:shipmentid' element={<BoxDetails />} />

					<Route exact path='/green-phox' element={<BoxManagement />} />


				</Route>
				<Route path='*' element={<PageNotFound />} />
				<Route path='/unauthorized' element={<Unauthorized />} />
			</Routes>
			<Toaster />
		</>
	);
}

export default App;
